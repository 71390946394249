<template>
  <v-card>
    <v-toolbar dark>
      <span>{{ song.title }}</span>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <span class="grey--text px-2">|</span>
        <span>{{ song.singer }}</span>
      </template>
      <span v-if="song.production" class="subtitle-2 grey--text pl-4"
        >℗ {{ song.production }}</span
      >
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <div
      class="my-2"
      :class="$vuetify.breakpoint.mdAndUp ? 'd-flex ' : ''"
    >
      <v-card-text class="ma-0 pa-0 text-center">
        <iframe
          :width="$vuetify.breakpoint.mdAndUp ? 600 : 360"
          :height="$vuetify.breakpoint.mdAndUp ? 350 : 220"
          :src="song.link"
          allow="fullscreen;"
          style="border: none"
          class="flex-grow-0"
        >
        </iframe>
      </v-card-text>
      <div style="width: 100%" :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : ''">
        <v-tabs v-model="tab" class="mb-2">
          <v-tab style="text-transform: none;">Details</v-tab>
          <v-tab style="text-transform: none;">Lyric</v-tab>
          <template v-if="tab === 0">
            <v-btn absolute fab dark x-small text color="grey" style="top: 10px; right: 10px;" @click="shareLink">
              <v-icon>mdi-share-variant</v-icon>
            </v-btn>
          </template>
          <template v-if="tab === 1 && lyrics_bn">
            <v-btn absolute fab dark x-small text color="grey" style="top: 10px; right: 10px;" @click="shareLyrics">
              <v-icon>mdi-share-variant</v-icon>
            </v-btn>
            <v-btn absolute fab dark x-small text color="grey" style="top: 10px; right: 40px" @click="downloadLyrics">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card-text
              class="grey--text text--lighten-1"
              style="overflow-y: auto"
              :style="{ height: $vuetify.breakpoint.mdAndUp ? '300px' : '200px' }"
            >
              <p><span class="grey--text">Song: </span>{{ song.title }} ({{ song.title_bn }})</p>
              <p><span class="grey--text">Singer: </span>{{ song.singer }}</p>
              <p><span class="grey--text">Lyricist: </span>{{ song.lyricist }}</p>
              <p><span class="grey--text">Composer: </span>{{ song.composer }}</p>
              <p v-if="song.album !== 'Single'"><span class="grey--text">Album: </span>{{ song.album }}</p>
              <p v-if="song.release_year"><span class="grey--text">Year of release: </span>{{ song.release_year }}</p>
              <p v-if="song.production">℗ {{ song.production }}</p>
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-card-text
              class="grey--text text--lighten-1 text-pre-formatted disable-text-selection overflow-y-only"
              :style="{ height: $vuetify.breakpoint.mdAndUp ? '300px' : '200px' }"
            >
              <div v-if="lyrics_bn">
                {{ lyrics_bn }}
              </div>
              <div v-else class="mt-6 text-center subtitle">
                Lyric is not available yet
              </div>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import downloadjs from 'downloadjs'

export default {
  props: {
    song: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data: () => ({
    tab: 0,
    lyrics_bn: ''
  }),
  watch: {
    song () {
      this.tab = 0
      this.lyrics_bn = ''
    },
    tab () {
      if (this.tab === 1 && !this.lyrics_bn) {
        this.getLyric(this.song.song_id)
          .then(snapshot => {
            if (!snapshot.empty) {
              this.lyrics_bn = snapshot.docs[0].data().lyrics_bn
            }
          })
      }
    }
  },
  methods: {
    ...mapActions(['getLyric']),
    shareLink () {
      try {
        navigator.share({
          title: `${this.song.title_bn} (${this.song.title})`,
          text: `${this.song.title} (${this.song.title_bn})\nSinger: ${this.song.singer}\nLyricist: ${this.song.lyricist}\nComposer: ${this.song.composer}\n`,
          url: `https://goutamghosal.com/discography?song_id=${this.song.song_id}`
        })
      } catch (error) {
        this.showToast({ text: 'Could not share', color: 'error' })
      }
    },
    shareLyrics () {
      try {
        navigator.share({
          title: `${this.song.title_bn} (${this.song.title})`,
          text: `${this.song.title_bn}\n----------------------------------------\n\n${this.lyrics_bn}\n`,
          url: `https://goutamghosal.com/discography?song_id=${this.song.song_id}`
        })
      } catch (error) {
        this.showToast({ text: 'Could not share', color: 'error' })
      }
    },
    downloadLyrics () {
      let data = `${this.song.title} (${this.song.title_bn})`
      data += `\nSinger: ${this.song.singer}`
      data += `\nLyricist: ${this.song.lyricist}`
      data += `\nComposer: ${this.song.composer}`
      data += '\n-------------------------------------------------'
      data += `\n\n${this.lyrics_bn}`
      data += '\n\n\nGenerated by: https://goutamghosal.com\n'
      const filename = `${this.song.title} (${this.song.title_bn}) Lyrics.txt`
      downloadjs(data, filename, 'text/plain;charset=UTF-8')
    }
  }
}
</script>
